import React, { useState, useEffect } from 'react';
import { useParams,useNavigate,Link } from 'react-router-dom';


import axios from 'axios';
import { Button, TextField, Container, Box } from '@mui/material';
import config from "../config"; // Import the configuration




function EditNotice() {
    const [notice, setNotice] = useState(null);
    const { id } = useParams();
    const navigate = useNavigate();
  
    const getAuthHeaders = () => {
      const token = localStorage.getItem("token");
      return {
        "Authorization": `Bearer ${token}`,
      };
    };

    useEffect(() => {
      const fetchNotice = async () => {
        try {
          const response = await axios.get(`${config.API_HOST}/api/notices/${id}`, {
            headers: getAuthHeaders(), // Use the headers with the Authorization token
          });
          setNotice(response.data);
        } catch (error) {
          console.error('Error fetching notice', error);
        }
      };
  
      fetchNotice();
    }, [id]);
  
    const handleChange = (event) => {
      const { name, value } = event.target;
      setNotice((prevNotice) => ({ ...prevNotice, [name]: value }));
    };
  
    const handleSubmit = async (event) => {
      event.preventDefault();
  
      try {
        const headers = getAuthHeaders();

        await axios.put(`${config.API_HOST}/api/notices/${id}`, notice, {
          headers: headers,
        });
        navigate('/notices');
      } catch (error) {
        console.error('Error updating notice', error);
      }
    };
  
    if (!notice) {
      return <p>Loading...</p>;
    }

  return (
    <div>
       <Container>
       
      <h1>Edit Notice</h1>
      <form onSubmit={handleSubmit}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            name="title"
            label="Title"
            value={notice.title}
            onChange={handleChange}
            required
            fullWidth
          />
          <TextField
            name="content"
            label="Content"
            value={notice.content}
            onChange={handleChange}
            required
            fullWidth
            multiline
            rows={10}
          />
          <Button variant="contained" color="primary" type="submit">
            Save
          </Button>
          <Button component={Link} to="/notices" variant="outlined" color="secondary">
                Notice Home
              </Button>
        </Box>
      </form>
    </Container>
    </div>
  );
}

export default EditNotice;