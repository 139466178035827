import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField } from '@mui/material';
import config from "../config";
import Pagination from '@mui/material/Pagination';

const rowsPerPage = 20; // Set the number of rows you want per page

const NoticePage = () => {
  const [notices, setNotices] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const navigate = useNavigate();

  // Fetch notices from the API when the component mounts
  useEffect(() => {
    const fetchNotices = async () => {
      try {
        const response = await axios.get(`${config.API_HOST}/api/notices`, {
          headers: getAuthHeaders(),
        });
        setNotices(response.data);
      } catch (error) {
        console.error('Error fetching notices', error);
      }
    };
    fetchNotices();
  }, []);

  const getAuthHeaders = () => {
    const token = localStorage.getItem("token");
    return {
      "Authorization": `Bearer ${token}`,
    };
  };

  const handleNoticeClick = (noticeId) => {
    navigate(`/notices/${noticeId}`);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const filteredNotices = notices.filter((notice) =>
    notice.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    notice.content.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Pagination: Get only the notices for the current page
  const paginatedNotices = filteredNotices.slice((page - 1) * rowsPerPage, page * rowsPerPage);

  return (
    <div>
      <h1>Notices</h1>
      <Link to="/create" style={{ textDecoration: 'none' }}>
        <Button variant="contained" color="primary">
          Create Notice
        </Button>
      </Link>
      <div>
        <TextField
          id="search-input"
          label="Search"
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={handleSearchChange}
          style={{ marginRight: '20px' }}
        />
        <Pagination count={Math.ceil(filteredNotices.length / rowsPerPage)} page={page} onChange={handlePageChange} />
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Row</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Post Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedNotices.map((notice, index) => (
              <TableRow key={notice.noticeId}>
                <TableCell>{(page - 1) * rowsPerPage + index + 1}</TableCell>
                <TableCell>
                  <a
                    href="#"
                    onClick={() => handleNoticeClick(notice.noticeId)}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                      {notice.title}
                    </a>
                  </TableCell>
                  <TableCell>{notice.postTime}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };
  
  export default NoticePage;
  
