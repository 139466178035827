import React from "react";
import { Container, TextField, Button, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useNavigate,Link } from "react-router-dom";
import config from "../config"; // Import the configuration

const CreateNotice = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const getAuthHeaders = () => {
    const token = localStorage.getItem("token");
    return {
      "Authorization": `Bearer ${token}`,
    };
  };

  const onSubmit = async (data) => {
    // Get the current time in a format compatible with Oracle database
    const currentTime = new Date().toISOString().replace("T", " ").substring(0, 19);
    

    // Set the default values for the other fields
    const noticeWithDefaults = {
      ...data,
      visible: "1",
      postTime: currentTime,
      type: "TEXT",
      pdfFileName: "",
      userId: 1, // Replace with the actual user login ID
    };

    try {
        const headers = getAuthHeaders();
      await axios.post(`${config.API_HOST}/api/notices/create`, noticeWithDefaults, {
        headers: headers,
      });
      navigate("/notices");
    } catch (error) {
      console.error("Error creating notice", error);
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Create Notice
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          label="Title"
          {...register("title", { required: true })}
          error={errors.title ? true : false}
          helperText={errors.title && "Title is required"}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Content"
          {...register("content", { required: true })}
          error={errors.content ? true : false}
          helperText={errors.content && "Content is required"}
          fullWidth
          margin="normal"
          multiline
          rows={10}
        />
        <Button type="submit" variant="contained" color="primary">
          Create
        </Button>
        <Button component={Link} to="/notices" variant="outlined" color="secondary">
                Notice Home
              </Button>
      </form>
    </Container>
  );
};

export default CreateNotice;
