import React, { useState, useEffect } from 'react';

const Gyroscope = () => {
  const [gyroscopeData, setGyroscopeData] = useState({ alpha: 0, beta: 0, gamma: 0 });

  useEffect(() => {
    const handleDeviceOrientation = (event) => {
      setGyroscopeData({
        alpha: event.alpha,
        beta: event.beta,
        gamma: event.gamma,
      });
    };

    window.addEventListener('deviceorientation', handleDeviceOrientation);

    return () => {
      window.removeEventListener('deviceorientation', handleDeviceOrientation);
    };
  }, []);

  return (
    <div>
      <p>Alpha: {gyroscopeData.alpha}</p>
      <p>Beta: {gyroscopeData.beta}</p>
      <p>Gamma: {gyroscopeData.gamma}</p>
    </div>
  );
};

export default Gyroscope;