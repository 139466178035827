import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import LoginPage from "./pages/LoginPage";
import NoticePage from "./pages/NoticePage";
import EditNotice from './pages/EditNotice';
import CreateNotice from './pages/CreateNotice';
import Gyroscope from './pages/Gyroscope';

const theme = createTheme({
  // You can customize the theme here, or use the default theme.
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/notices" element={<NoticePage />} />
            <Route path="/notices/:id" element={<EditNotice />} />
            <Route path="/create" element={<CreateNotice />} />
            <Route path="/compass" element={<Gyroscope  />} />
          </Routes>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
