import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { TextField, Button } from "@mui/material";
import config from "../config"; // Import the configuration

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${config.API_HOST}/login`, {
        username,
        password,
      });
      if (response.status === 200) {
        // Store the token in local storage or any state management library
        localStorage.setItem("token", response.data.token);
  
        // Configure axios to use the token for all requests
        axios.defaults.headers.common["Authorization"] = `Bearer ${response.data.token}`;
  
        // Navigate to the notices page
        navigate('/notices');
      }
    } catch (error) {
      console.error("Login failed", error);
    }
  };

  return (
    <div>
      <h1>Login</h1>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button type="submit" variant="contained">
          Login
        </Button>
      </form>
      version : 1.0
    </div>
  );
};

export default LoginPage;
